import React, { useState, useRef } from "react";
import { Navbar, Container, Nav, Button, Offcanvas, NavDropdown, Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faLocationDot, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import './headerStyle.css';
import brandWhite from '../../assets/image/nav-white.png';
import Promo from '../../assets/image/bg-spektra.png';
import promo2 from '../../assets/image/bg-kinto.png';
import mobilePromo from '../../assets/image/bg-mobile-spektra.png';
import mobilePromo2 from '../../assets/image/bg-mobile-kinto.png';

export default function HeaderTrans ()
{
    const location = useLocation();
    const currentPath = location.pathname;
    const [ show, setShow ] = useState( 1 );
    const containerRef = useRef( null );
    const [ scrollPosition, setScrollPosition ] = useState( 0 );

    const handleScroll = () =>
    {
        const scrollLeft = containerRef.current?.scrollLeft || 0;
        setScrollPosition( scrollLeft );
    };

    const handleSwipe = ( direction ) =>
    {
        const container = containerRef.current;
        const containerWidth = container.clientWidth;
        const newPosition = direction === "left"
            ? Math.max( scrollPosition - containerWidth, 0 )
            : Math.min( scrollPosition + containerWidth, container.scrollWidth - containerWidth );

        container.scrollTo( { left: newPosition, behavior: "smooth" } );
        setScrollPosition( newPosition );
    };

    const renderPaginationDots = () =>
    {
        const container = containerRef.current;
        const containerWidth = container?.clientWidth || 1;
        const scrollWidth = container?.scrollWidth - containerWidth + 1 || 0;
        const pageCount = Math.ceil( scrollWidth / containerWidth );
        const dots = [];

        for ( let i = 0; i < pageCount; i++ )
        {
            dots.push(
                <div
                    key={ i }
                    className={ `pagination-dot ${ Math.round( i * containerWidth ) === scrollPosition ? "active" : "" }` }
                    onClick={ () => handleDotClick( i ) }
                ></div>
            );
        }
        return dots;
    };

    const handleDotClick = ( index ) =>
    {
        const container = containerRef.current;
        const newPosition = index * ( container?.clientWidth || 0 );

        container.scrollTo( { left: newPosition, behavior: "smooth" } );
        setScrollPosition( newPosition );
    };

    return (
        <>
            <header>
                <div className="web-display">
                    <div style={ { width: "100%", padding: '0px 5px', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", } }>
                        { show === 1 && (
                            <div style={ { position: "relative", width: '100%' } }>
                                <Link to="/toyotaspec">
                                    <img style={ { width: "100%" } } src={ Promo } alt="Spektra" />
                                </Link>
                            </div>
                        ) }
                        { show === 2 && (
                            <div style={ { position: "relative", width: '100%' } }>
                                <Link to="/kinto">
                                    <img style={ { width: "100%" } } src={ promo2 } alt="Kinto" />
                                </Link>
                            </div>
                        ) }

                        <div style={ { width: "100%", display: "flex", justifyContent: "space-between", padding: "0px 20px", position: "absolute", } }>
                            <div className="LineDiv">
                                { [ 1, 2 ].map( ( num ) => (
                                    <div
                                        key={ num }
                                        onClick={ () => setShow( num ) }
                                        className={ show === num ? "orangeLine" : "grayDot" }
                                    >
                                        <div className={ show === num ? "orangeLine-2" : "grayDot" } />
                                    </div>
                                ) ) }
                            </div>
                            <div>
                                <div style={ { marginBottom: "10px" } } onClick={ () => setShow( show === 1 ? 2 : show - 1 ) } className={ show === 1 ? "sliderCircleDull" : "sliderCircle" }>
                                    <FontAwesomeIcon icon={ faAngleLeft } />
                                </div>
                                <div onClick={ () => setShow( show === 2 ? 1 : show + 1 ) } className={ show === 2 ? "sliderCircleDull" : "sliderCircle" }>
                                    <FontAwesomeIcon icon={ faAngleRight } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile-display">
                    <div
                        ref={ containerRef }
                        className="scroll-container"
                        onScroll={ handleScroll }
                    >
                        <img style={ { width: "100%" } } src={ mobilePromo } alt="Spektra" />
                        <img style={ { width: "100%" } } src={ mobilePromo2 } alt="Kinto" />
                    </div>
                    <div className="pagination-dots">{ renderPaginationDots() }</div>
                </div>

                <Navbar bg="dark" expand="lg" className="babababa">
                    <div className="container-fluid">
                        <Navbar.Brand href="#" className="navbar-brand">
                            <img src={ brandWhite } alt="Brand" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="offcanvasNavbar" />
                        <Navbar.Offcanvas
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                            placement="start"
                            style={ { backgroundColor: 'black' } }
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id="offcanvasNavbarLabel">
                                    <img src={ brandWhite } className="side-bar-logo" alt="Sidebar Logo" />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>

                                <Nav className="jus-newheader flex-grow-1 pe-3 justify-content-center" style={ { color: 'white' } }>
                                    <Nav.Link
                                        href="https://itstimeforeveryone.toyota.astra.co.id"
                                        target="_blank"
                                        className="white"
                                        style={ { color: 'white' } }
                                    >
                                        Toyota Electrified
                                    </Nav.Link>

                                    <Nav.Link
                                        href="https://gazooracing.toyota.astra.co.id/"
                                        target="_blank"
                                        className="white"
                                        style={ { color: 'white' } }
                                    >
                                        Gazoo Racing
                                    </Nav.Link>

                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={ { color: 'white' } }>
                                            Models
                                        </Dropdown.Toggle>
                                    </Dropdown>

                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={ { color: 'white' } }>
                                            Shopping Tools
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <Link to="/kinto" className={ currentPath === "/kinto" ? "active-link-text" : "link-text-2 black" }  style={ { color: 'white' } }>
                                                    Kinto
                                                </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <Link to="/toyotaspec" className={ currentPath === "/toyotaspec" ? "active-link-text" : "link-text-2 black" } style={ { color: 'white' } }>
                                                    Spectra
                                                </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <Link to="/ezdeal" className={ currentPath === "/ezdeal" ? "active-link-text" : "link-text-2 black" } style={ { color: 'white' } }>
                                                    EZ Deal
                                                </Link>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={ { color: 'white' } }>
                                            Ownership
                                        </Dropdown.Toggle>
                                    </Dropdown>

                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={ { color: 'white' } }>
                                            More
                                        </Dropdown.Toggle>
                                    </Dropdown>
                                </Nav>


                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link>
                                        <FontAwesomeIcon icon={ faMagnifyingGlass } className="icon-nav" />
                                    </Nav.Link>
                                    <Nav.Link>
                                        <FontAwesomeIcon icon={ faLocationDot } className="icon-nav" />
                                    </Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </div>
                </Navbar>
            </header>
        </>
    );
}
