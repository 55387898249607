import React, { useState, useEffect } from "react";
import "./EZDealPageStyle.css";
import ZEDealImage from "../../../assets/image/bg-ezdeal.png";
import ZEDealVideo from "../../../assets/image/ZEDealVideo.png";
import ZEDealLogo1 from "../../../assets/image/ZEDealLogo1.png";
import ZEDealLogo2 from "../../../assets/image/ZEDealLogo2.png";
import ZEDealLogo3 from "../../../assets/image/ZEDealLogo3.png";
import Smart from "../../../assets/image/Smart.png";
import Fit from "../../../assets/image/Fit.png";
import Hemat from "../../../assets/image/Hemat.png";
import Mudah from "../../../assets/image/Mudah.png";
import ZEDealCar from "../../../assets/image/ZEDealCar.png";
import KintoSmall from "../../../assets/image/banner-spektra.png";
import ToyotaSpektakulerSmall from "../../../assets/image/banner-kinto.png";
import
{
  Navbar,
  Container,
  Nav,
  Button,
  Offcanvas,
  NavDropdown,
  Form,
  Card,
  Row,
  Col,
  Carousel,
  Accordion,
  Modal
} from "react-bootstrap";
import Footer from "../../footer";
import Header from "../../header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faCirclePlay, faPlay } from "@fortawesome/free-solid-svg-icons";
import ezdealmMbile from '../../../assets/image/bg-mobile-ezdeal.png'
import { Link } from "react-router-dom";
import YouTube from 'react-youtube';

const EZDeal = () =>
{
  const [ open, setOpen ] = useState( null );
  const [ buttonClick, setButtonClick ] = useState( 'button1' )

  const handleButtonClick1 = () =>
  {
    setButtonClick( 'button1' )
  }

  const handleButtonClick2 = () =>
  {
    setButtonClick( 'button2' )
  }

  const scrollToTop = () =>
  {
    // Smoothly scroll to the top of the page
    window.scrollTo( { top: 0, behavior: "smooth" } );
  };

  useEffect( () =>
  {
    window.scrollTo( 0, 0 );
  }, [] ); // Empty dependency array ensures this effect runs once when the component mounts

  const [ show, setShow ] = useState( false );
  const handleClose = () => setShow( false );
  const handleShow = () => setShow( true );
  const videoId = 'I-e8oip2AQU'; // Extracted from the YouTube link

  const opts = {
    width: '100%', // Adjust as needed
    height: '700px', // Adjust as needed
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <>
      <Header />
      <div
        style={ {
          width: "100%",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        } }
      >
        <div className="ZEDealImageDiv">
          <img style={ { width: "100%" } } src={ ZEDealImage } />
        </div>
        <div className="ZEDealImageDiv-mobile">
          <img style={ { width: "100%" } } src={ ezdealmMbile } />
        </div>

        <div className="ZEDealVideoDiv">
          <img style={ { width: "100%" } } src={ ZEDealVideo } />
          <FontAwesomeIcon icon={ faPlay }

            className="play-image"
            onClick={ handleShow }
          />
        </div>
        <div className="ZETwoLogo">
          <div>
            <img style={ { width: "100%" } } src={ ZEDealLogo1 } />
          </div>
          <div>
            <img style={ { width: "100%" } } src={ ZEDealLogo2 } />
          </div>
        </div>
        <div className="TextDiv1">
          Program finansial dari Toyota yang bikin kamu nggak perlu nunggu lagi <br></br>
          untuk punya mobil keren dan jadi makin kece!
        </div>
        <div className="TextDiv2">
          Segera hubungi dealer Toyota kesayanganmu dan get your Toyota now!
        </div>
        <div className="logoText">
          <div className="">Mengapa Pilih</div>
          <div>
            <img className="ZEDealLogo3" src={ ZEDealLogo3 } />
          </div>
          <div className="">?</div>
        </div>
        <div className="BoxMainDiv">
          <div className="BoxDiv1">
            <div className="box">
              <div>
                <img className="fitImage" src={ Smart } />
              </div>
              <div
                style={ {
                  padding: "0px 40px",
                } }
                className="ezdeal-box-content"
              >
                PUNYA MOBIL STYLISH <br></br> TANPA PERLU BAYAR MAHAL
              </div>
              <div style={ { padding: "0px 20px", fontFamily: "Nunito Sans" } }>
                Dengan budget cicilan untuk mobil standart, kamu bisa
                mendapatkan mobil yang lebih keren.
              </div>
            </div>
            <div className="box">
              <div>
                <img className="fitImage" src={ Hemat } />
              </div>

              <div
                style={ {
                  padding: "0px 40px",
                } }
                className="ezdeal-box-content"
              >
                CICILAN LEBIH RINGAN, <br></br>GAYA HIDUP TETAP AMAN
              </div>
              <div style={ { padding: "0px 20px", fontFamily: "Nunito Sans" } }>
                bayar cicilan EZDeal yang ringan tak akan mengganggu rencana
                keuanganmu.
              </div>
            </div>
          </div>
          <div className="BoxDiv1">
            <div className="box">
              <div>
                <img className="fitImage1" src={ Fit } />
              </div>

              <div
                style={ {
                  padding: "0px 40px",
                } }
                className="ezdeal-box-content"
              >
                CICILAN SESUAI DENGAN <br></br> PERKEMBANGAN KARIER KAMU
              </div>
              <div style={ { padding: "0px 20px", fontFamily: "Nunito Sans" } }>
                Cicilan berjenjang dalam tiga periode yang disesuaikan dengan
                perkembangan karier.
              </div>
            </div>
            <div className="box">
              <div>
                <img className="fitImage" src={ Mudah } />
              </div>

              <div
                style={ {
                  padding: "0px 40px",
                } }
                className="ezdeal-box-content"
              >
                TRADE-IN LEBIH MUDAH UNTUK <br></br>TETAP UP TO DATE
              </div>
              <div style={ { padding: "0px 20px", fontFamily: "Nunito Sans" } }>
                Hidup lebih mudah dengan 2 pilihan, trade-in ke mobil Toyota
                baru, atau lanjutkan cicilan sampai periode terakhir.
              </div>
            </div>
          </div>
        </div>
        <div className="accordianUpperDiv">
          <div className="AccordianHeading">Frequently Asked Questions</div>
          <div className="blueButtonDiv">
            <div>
              <button className={ buttonClick == 'button1' ? "BlueButton1" : 'BlueButton2' } onClick={ handleButtonClick1 }>EZ Deal</button>
            </div>
            <div>
              <button className={ buttonClick == 'button2' ? "BlueButton1" : 'BlueButton2' } onClick={ handleButtonClick2 }>EZ Deal Syariah</button>
            </div>
          </div>

          { buttonClick == 'button1' ? (
            <>
              <div className="accordianMainDiv">
                <div
                  className="accordianDiv1"
                  onClick={ () => ( open === 1 ? setOpen( 0 ) : setOpen( 1 ) ) }
                >
                  <div
                    style={ {
                      color: open === 1 ? "#0088AA" : "black",
                      fontWeight: "900",
                      fontFamily: "Nunito Sans",
                    } }
                  >
                    Dimana saya bisa membeli paket EZDeal?
                  </div>
                  <div style={ { fontSize: "25px", color: open === 1 ? "#0088AA" : "black" } }>{ open === 1 ? "-" : "+" }</div>
                </div>
                { open === 1 && (
                  <div className="accordianDiv2">
                    Saat ini, EZDeal tersedia di seluruh cabang Toyota se-indonesia
                    dengan pembiayaan kredit melalui TAFs (Toyota Astra Financial
                    Services) dan ACC (Astra Credit Companies).
                  </div>
                ) }
                <div
                  style={ {
                    width: "100%",
                    border: "1px solid black",
                    height: "1px",
                    backgroundColor: "black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  } }
                ></div>
              </div>
              <div className="accordianMainDiv">
                <div
                  className="accordianDiv1"
                  onClick={ () => ( open === 2 ? setOpen( 0 ) : setOpen( 2 ) ) }
                >
                  <div
                    style={ {
                      color: open === 2 ? "#0088AA" : "black",
                      fontWeight: "900",
                      fontFamily: "Nunito Sans",
                    } }
                  >
                    Model apa saja yang bisa dipilih di paket EZDeal?
                  </div>
                  <div style={ { fontSize: "25px", color: open === 2 ? "#0088AA" : "black" } }>{ open === 2 ? "-" : "+" }</div>
                </div>
                { open === 2 && (
                  <div className="accordianDiv2">
                    Saat ini, EZDeal tersedia di seluruh cabang Toyota se-indonesia
                    dengan pembiayaan kredit melalui TAFs Raize, Rush, Yaris, dan Vios.
                  </div>
                ) }
                <div
                  style={ {
                    width: "100%",
                    border: "1px solid black",
                    height: "1px",
                    backgroundColor: "black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  } }
                ></div>
              </div>
              <div className="accordianMainDiv">
                <div
                  className="accordianDiv1"
                  onClick={ () => ( open === 3 ? setOpen( 0 ) : setOpen( 3 ) ) }
                >
                  <div
                    style={ {
                      color: open === 3 ? "#0088AA" : "black",
                      fontWeight: "900",
                      fontFamily: "Nunito Sans",
                    } }
                  >
                    Berapa lama periode pembiayaan yang ada?
                  </div>
                  <div style={ { fontSize: "25px", color: open === 3 ? "#0088AA" : "black" } }>{ open === 3 ? "-" : "+" }</div>
                </div>
                { open === 3 && (
                  <div className="accordianDiv2">
                    Tenor tersedia enam tahun dan dibagi menjadi tiga periode. Periode satu adalah cicilan pada masa dua tahun pertama, periode dua adalah masa cicilan tahun ketiga dan keempat, periode ketiga adalah tahun kelima dan keenam.
                  </div>
                ) }
                <div
                  style={ {
                    width: "100%",
                    border: "1px solid black",
                    height: "1px",
                    backgroundColor: "black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  } }
                ></div>
              </div>
              <div className="accordianMainDiv">
                <div
                  className="accordianDiv1"
                  onClick={ () => ( open === 4 ? setOpen( 0 ) : setOpen( 4 ) ) }
                >
                  <div
                    style={ {
                      color: open === 4 ? "#0088AA" : "black",
                      fontWeight: "900",
                      fontFamily: "Nunito Sans",
                    } }
                  >
                    Berapa DP minimum?Dapatkah disesuaikan dengan dana yang saya
                    miliki?
                  </div>
                  <div style={ { fontSize: "25px", color: open === 4 ? "#0088AA" : "black" } }>{ open === 4 ? "-" : "+" }</div>
                </div>
                { open === 4 && (
                  <div className="accordianDiv2">
                    Ya, kamu dapat menyesuaikan nilai pembayaran pertama dengan dana yang kamu miliki, dengan ketentuan minimal DP sebesar 20% dari harga OTR yang berlaku
                  </div>
                ) }
                <div
                  style={ {
                    width: "100%",
                    border: "1px solid black",
                    height: "1px",
                    backgroundColor: "black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  } }
                ></div>
              </div>
              <div className="accordianMainDiv">
                <div
                  className="accordianDiv1"
                  onClick={ () => ( open === 5 ? setOpen( 0 ) : setOpen( 5 ) ) }
                >
                  <div
                    style={ {
                      color: open === 5 ? "#0088AA" : "black",
                      fontWeight: "900",
                      fontFamily: "Nunito Sans",
                    } }
                  >
                    Mengapa cicilan dibagi menjadi tiga periode? Dan ada penyesuaian
                    tinggi di periode tiga?
                  </div>
                  <div style={ { fontSize: "25px", color: open === 5 ? "#0088AA" : "black" } }>{ open === 5 ? "-" : "+" }</div>
                </div>
                { open === 5 && (
                  <div className="accordianDiv2">
                    Karena kami ingin membantumu mendapatkan Toyota dengan harga yang kompetitif di saat keriermu masih berkembang. Penyesuaian di periode ketiga akan dicocokan dengan progresi kariermu. Selain itu, kamu tak perlu khawatir karena ada opsi trade-in di periode tiga, yang pasti terjamin keuntungannya.
                  </div>
                ) }
                <div
                  style={ {
                    width: "100%",
                    border: "1px solid black",
                    height: "1px",
                    backgroundColor: "black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  } }
                ></div>
              </div>
              <div className="accordianMainDiv">
                <div
                  className="accordianDiv1"
                  onClick={ () => ( open === 6 ? setOpen( 0 ) : setOpen( 6 ) ) }
                >
                  <div
                    style={ {
                      color: open === 6 ? "#0088AA" : "black",
                      fontWeight: "900",
                      fontFamily: "Nunito Sans",
                    } }
                  >
                    Dapatkah saya melakukan tukar tambah semasa cicilan berjalan?
                  </div>
                  <div style={ { fontSize: "25px", color: open === 6 ? "#0088AA" : "black" } }>{ open === 6 ? "-" : "+" }</div>
                </div>
                { open === 6 && (
                  <div className="accordianDiv2">
                    Tentu bisa. Kamu dapat menukar Toyota-mu dengan model terbaru kapan pun sesuai dengan kebutuhan terbaru atau pencapaian kariermu dengan trade-in di dealer dan mendapatkan Toyota yang kamu butuhkan.
                  </div>
                ) }
                <div
                  style={ {
                    width: "100%",
                    border: "1px solid black",
                    height: "1px",
                    backgroundColor: "black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  } }
                ></div>
              </div>
            </>
          )
            : (
              <>
                <div className="accordianMainDiv">
                  <div
                    className="accordianDiv1"
                    onClick={ () => ( open === 7 ? setOpen( 0 ) : setOpen( 7 ) ) }
                  >
                    <div
                      style={ {
                        color: open === 7 ? "#0088AA" : "black",
                        fontWeight: "900",
                        fontFamily: "Nunito Sans",
                      } }
                    >
                      Bolehkah untuk Non muslim mengambil produk syariah ?
                    </div>
                    <div style={ { fontSize: "25px", color: open === 7 ? "#0088AA" : "black" } }>{ open === 7 ? "-" : "+" }</div>
                  </div>
                  { open === 7 && (
                    <div className="accordianDiv2">
                      Boleh saja karena untuk produk syariah ini bisa dinikmati oleh yang Muslim ataupun yang Non Muslim .
                    </div>
                  ) }
                  <div
                    style={ {
                      width: "100%",
                      border: "1px solid black",
                      height: "1px",
                      backgroundColor: "black",
                      marginTop: "10px",
                      marginBottom: "10px",
                    } }
                  ></div>
                </div>
                <div className="accordianMainDiv">
                  <div
                    className="accordianDiv1"
                    onClick={ () => ( open === 8 ? setOpen( 0 ) : setOpen( 8 ) ) }
                  >
                    <div
                      style={ {
                        color: open === 8 ? "#0088AA" : "black",
                        fontWeight: "900",
                        fontFamily: "Nunito Sans",
                      } }
                    >
                      Apakah perbedaan EZ Deal Konvensional dengan EZ Deal Syariah ?
                    </div>
                    <div style={ { fontSize: "25px", color: open === 8 ? "#0088AA" : "black" } }>{ open === 8 ? "-" : "+" }</div>
                  </div>
                  { open === 8 && (
                    <div className="accordianDiv2">
                      1. Perbedaan terdapat dalam akad dimana syariah menggunakan akad murabahah sedangkan konvensional menggunakan prinsip bebas nilai <br />
                      2. Konvensional menggunakan sistem bunga sedangkan Syariah menggunakan sistem margin yang di tentukan sesuai kesepakatan jual beli
                    </div>
                  ) }
                  <div
                    style={ {
                      width: "100%",
                      border: "1px solid black",
                      height: "1px",
                      backgroundColor: "black",
                      marginTop: "10px",
                      marginBottom: "10px",
                    } }
                  ></div>
                </div>
                <div className="accordianMainDiv">
                  <div
                    className="accordianDiv1"
                    onClick={ () => ( open === 9 ? setOpen( 0 ) : setOpen( 9 ) ) }
                  >
                    <div
                      style={ {
                        color: open === 9 ? "#0088AA" : "black",
                        fontWeight: "900",
                        fontFamily: "Nunito Sans",
                      } }
                    >
                      Akad yang digunakan dalam produk EZ Deal syariah ini apa?
                    </div>
                    <div style={ { fontSize: "25px", color: open === 9 ? "#0088AA" : "black" } }>{ open === 9 ? "-" : "+" }</div>
                  </div>
                  { open === 9 && (
                    <div className="accordianDiv2">
                      Akad yang digunakan adalah murabahah (jual beli barang dengan harga asal dengan keuntungan yang disepakati).
                    </div>
                  ) }
                  <div
                    style={ {
                      width: "100%",
                      border: "1px solid black",
                      height: "1px",
                      backgroundColor: "black",
                      marginTop: "10px",
                      marginBottom: "10px",
                    } }
                  ></div>
                </div>
                <div className="accordianMainDiv">
                  <div
                    className="accordianDiv1"
                    onClick={ () => ( open === 10 ? setOpen( 0 ) : setOpen( 10 ) ) }
                  >
                    <div
                      style={ {
                        color: open === 10 ? "#0088AA" : "black",
                        fontWeight: "900",
                        fontFamily: "Nunito Sans",
                      } }
                    >
                      Paket syariah ini apakah sudah bisa digunakan diseluruh cabang
                      ACC & TAF?{ " " }
                    </div>
                    <div style={ { fontSize: "25px", color: open === 10 ? "#0088AA" : "black" } }>{ open === 10 ? "-" : "+" }</div>
                  </div>
                  { open === 10 && (
                    <div className="accordianDiv2">
                      EZ Deal Syariah tersedia di seluruh cabang Toyota, dengan pembiayaan oleh TAF (Toyota Astra Finance).
                    </div>
                  ) }
                  <div
                    style={ {
                      width: "100%",
                      border: "1px solid black",
                      height: "1px",
                      backgroundColor: "black",
                      marginTop: "10px",
                      marginBottom: "10px",
                    } }
                  ></div>
                </div>
                <div className="accordianMainDiv">
                  <div
                    className="accordianDiv1"
                    onClick={ () => ( open === 11 ? setOpen( 0 ) : setOpen( 11 ) ) }
                  >
                    <div
                      style={ {
                        color: open === 11 ? "#0088AA" : "black",
                        fontWeight: "900",
                        fontFamily: "Nunito Sans",
                      } }
                    >
                      Berapa bunga yang digunakan dalam akad syariah?
                    </div>
                    <div style={ { fontSize: "25px", color: open === 11 ? "#0088AA" : "black" } }>{ open === 11 ? "-" : "+" }</div>
                  </div>
                  { open === 11 && (
                    <div className="accordianDiv2">
                      Dalam syariah kami tidak mengenal bunga, namun bisa digunakan margin sesuai dengan ketentuan dan kesepakatan diawal antara objek yang melakukan jual beli.
                    </div>
                  ) }
                  <div
                    style={ {
                      width: "100%",
                      border: "1px solid black",
                      height: "1px",
                      backgroundColor: "black",
                      marginTop: "10px",
                      marginBottom: "10px",
                    } }
                  ></div>
                </div>
                <div className="accordianMainDiv">
                  <div
                    className="accordianDiv1"
                    onClick={ () => ( open === 12 ? setOpen( 0 ) : setOpen( 12 ) ) }
                  >
                    <div
                      style={ {
                        color: open === 12 ? "#0088AA" : "black",
                        fontWeight: "900",
                        fontFamily: "Nunito Sans",
                      } }
                    >
                      Apakah produk ini benar-benar produk syariah?
                    </div>
                    <div style={ { fontSize: "25px", color: open === 12 ? "#0088AA" : "black" } }>{ open === 12 ? "-" : "+" }</div>
                  </div>
                  { open === 12 && (
                    <div className="accordianDiv2">
                      Ya EZ deal Syariah ini benar-benar produk syariah karena secara prinsip menggunakan asas jual-beli, dan pendapatan yang diterima perusahaan pembiayaan berasal dari margin halal yang didapatkan atas kesepakatan bersama antara penjual dan pembeli. Hukum jual-beli diperbolehkan dalam syariah, maka pola perusahaan pembiayaan semacam ini pun diizinkan selama tidak melanggar syariat.
                    </div>
                  ) }
                  <div
                    style={ {
                      width: "100%",
                      border: "1px solid black",
                      height: "1px",
                      backgroundColor: "black",
                      marginTop: "10px",
                      marginBottom: "10px",
                    } }
                  ></div>
                </div>
              </>
            ) }
        </div>
        <Container>
          <div className='kinto-last-box'>
            <div className='kinto-name-ez'>
              <h4>
                Promo Lainnya
              </h4>
            </div>

            <div className="bottomMainDiv-main-toyota-ezdeal">

              <div className="bottomMainDiv1">
                <div className="bottomDiv2">
                  <img
                    style={ { width: "100%", height: "auto" } }
                    src={ ToyotaSpektakulerSmall }
                  />
                </div>
                <div className="bottomDiv1">
                  <div
                    style={ {
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "10px 0px 10px 0px",
                    } }
                  >
                    <div className="card-title-main">
                      KINTO
                    </div>
                    <div className="card-para-main">
                      Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya untukmu - no DP, bebas pajak progresif, biaya servis, parts & asuransi.
                    </div>
                    <div>
                      <Link to='/kinto'>
                        <button className="box-2-button">READ MORE</button>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
              <div className="bottomMainDiv1">
                <div className="bottomDiv2">
                  <img
                    style={ { width: "100%", height: "auto" } }
                    src={ KintoSmall }
                  />
                </div>
                <div className="bottomDiv1">
                  <div
                    style={ {
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "10px 0px 10px 0px",
                    } }
                  >
                    <div className="card-title-main">
                      Toyota Spektakuler
                    </div>
                    <div className="card-para-main">
                      Beli Avanza, Agya, Veloz, atau Rush dan dapatkan gratis paket suku cadang Rp 2.6 Juta, asuransi 2 tahun Rp 16 Juta, dan paket T-Care. Cek Promo Toyota Spektakuler sekarang!
                    </div>
                    <div>
                      <Link to='/toyotaspec'>
                        <button className="box-2-button">READ MORE</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bottom-button" style={ { fontWeight: "100" } }>
        <hr />
        <button onClick={ scrollToTop }>
          Back to top <FontAwesomeIcon icon={ faAngleUp } />
        </button>
      </div>
      <hr />

      <Modal show={ show } onHide={ handleClose } size="xl">
        <Modal.Header closeButton>
        </Modal.Header>
        <YouTube videoId={ videoId } opts={ opts } />
      </Modal>
      <Footer />
    </>
  );
};

export default EZDeal;
