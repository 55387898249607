import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Button, Offcanvas, NavDropdown, Form, Dropdown } from 'react-bootstrap'
import logo from '../../logo.svg';
import logod from '../../assets/image/logo.png'
import { faCircleXmark, faFilm, faCaretDown, faSmile, faClock, faAngleDown, faCalendar, faBolt, faCircleInfo, faSortDown, faCaretUp, faWandMagicSparkles, faForwardFast, faMugHot, faMinimize, faUserTie, faMaximize, faSpellCheck, faHandFist, faHandBackFist, faMugSaucer, faCoffee, faFileMedical, faMagnifyingGlass, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './headerNewStyle.css'
import Promo from '../../assets/image/Promo.png'
import brandWhite from '../../assets/image/nav-white.png'

export default function Header ()
{
    const location = useLocation()
    console.log( "loc==>", location.pathname );
    const currentPath = location.pathname

    return (
        <>
            <header>
                { [ 'lg' ].map( ( expand ) => (
                    <Navbar key={ expand } bg="white" expand={ expand } className="">
                        <div className="container-fluid" >
                            <Navbar.Brand href="#" className="navbar-brand-newheader"><img src={ logod } /></Navbar.Brand>
                            <Navbar.Toggle aria-controls={ `offcanvasNavbar-expand-${ expand }` } />

                            <Navbar.Offcanvas
                                id={ `offcanvasNavbar-expand-${ expand }` }
                                aria-labelledby={ `offcanvasNavbarLabel-expand-${ expand }` }
                                placement="start"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={ `offcanvasNavbarLabel-expand-${ expand }` }>
                                        <img src={ logod } className="side-bar-logo-newheader"></img>
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>

                                    <Nav className="jus-newheader flex-grow-1 pe-3 justify-content-center">
                                        <Nav.Link
                                            href="https://itstimeforeveryone.toyota.astra.co.id"
                                            target="_blank"
                                        >
                                            Toyota Electrified
                                        </Nav.Link>

                                        <Nav.Link
                                            href="https://gazooracing.toyota.astra.co.id/"
                                            target="_blank"
                                        >
                                            Gazoo Racing
                                        </Nav.Link>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Models
                                            </Dropdown.Toggle>
                                            {/* <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu> */}
                                        </Dropdown>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Shop Now
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item >
                                                    <Link to="/kinto" className={ currentPath === "/kinto" ? "active-link-text black" : "link-text-2" }>
                                                        Kinto
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item >
                                                    <Link to="/toyotaspec" className={ currentPath === "/toyotaspec" ? "active-link-text black" : "link-text-2" }>
                                                        Spectra
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item >
                                                    <Link to="/ezdeal" className={ currentPath === "/ezdeal" ? "active-link-text black" : "link-text-2" }>
                                                        EZ Deal
                                                    </Link>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Ownership
                                            </Dropdown.Toggle>
                                            {/* <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu> */}
                                        </Dropdown>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                More
                                            </Dropdown.Toggle>
                                            {/* <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu> */}
                                        </Dropdown>
                                    </Nav>

                                    <Nav className="justify-content-end flex-grow-1 pe-3">
                                        <Nav.Link>   <FontAwesomeIcon icon={ faMagnifyingGlass } /></Nav.Link>
                                        <Nav.Link>   <FontAwesomeIcon icon={ faLocationDot } /></Nav.Link>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>

                        </div >
                    </Navbar>
                ) ) }
            </header>
        </>
    )
}