import React, { useEffect, useState } from 'react'


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './homePageStyle.css'
import Mpv from '../../../component/car_tabs/MPV';
import { BASE_URL, Token } from '../../../Config';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function MobileTabs() {
    const [value, setValue] = React.useState(1);
    const [tabData, setTabData] = useState([]);


    const [productCate, setProductCate] = useState([])
    const [productList, setProductList] = useState([])


    useEffect(() => {

        handleProductCategory()


        handleFecthData()
    }, [Token])


    const handleFecthData = async () => {
        try {


            const response = await fetch(`${BASE_URL}/api/frontend/products?category=1`, {
                headers: {

                    Authorization: `Bearer ${Token}`,


                }
            });
            const data = await response.json();
            console.log("published-product", data);
            setProductList(data)






        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }
    }





    const handleProductCategory = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/frontend/product-categories`, {
                headers: {

                    Authorization: `Bearer ${Token}`,


                }
            });
            const data = await response.json();
            console.log("published/profile", data);
            setTabData(data);





        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }

    }



    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log("swcdaw", newValue)
    };


    useEffect(() => {
        // Fetch additional data based on the selected tab's ID

        handleProductList()
    }, [value, tabData]);


    const handleProductList = async () => {


        if (tabData.length > 0) {
            const selectedTabId = tabData[value].id;
            try {


                const response = await fetch(`${BASE_URL}/api/frontend/products?category=${selectedTabId}`, {
                    headers: {

                        Authorization: `Bearer ${Token}`,
                    }
                });
                const data = await response.json();
                console.log("published-product", data);
                setProductList(data)

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }

    }

    return (
        <>


            {/* <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}> */}
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                    sx={{
                        '& .MuiTab-textColorPrimary.Mui-selected': {
                            color: 'black', // Active tab text color
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'red', // Indicator color
                            height: 4,
                        },
                    }}
                >
                    {/* <Tab label="MPV" />
                    <Tab label="SUV" />
                    <Tab label="Hatchback" />
                    <Tab label="Sedan" />
                    <Tab label="Commercial" /> */}

                    {tabData.map((tab, index) => (
                        <Tab key={index} label={tab.name} />
                    ))}
                </Tabs>




                {/* Render TabPanels for each tab */}
                {/* <TabPanel value={value} index={1}>
                    <Mpv />
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <Mpv />
                </TabPanel>

                <TabPanel value={value} index={3}>
                    <Mpv />
                </TabPanel>

                <TabPanel value={value} index={4}>
                    <Mpv />
                </TabPanel>

                <TabPanel value={value} index={5}>
                    <Mpv />
                </TabPanel> */}

                {tabData.map((tab, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        {/* Assuming you want to pass the ID to Mpv component */}
                        <Mpv data={productList} />
                    </TabPanel>
                ))}



            </Box >
        </>
    )
}