import React from 'react';
import './App.css'; 
import PageRouter from './view/router/Router';

function App() {
  return (
    <div className="App">
      <PageRouter />
    </div>
  );
}

export default App;
