import { Route, HashRouter, BrowserRouter as Router, Routes, Navigate, useNavigate } from "react-router-dom";
import HomePage from "../pages/homepage";
import Toyotaspec from "../pages/toyotaspecta";
import EZDeal from "../pages/EZdeal";
import Kinto from "../pages/Kinto";

const PageRouter = ( props ) =>
{
    return (
        <HashRouter>
            <Routes>
                <Route path="/" exact element={ <HomePage /> } />
                <Route path="/toyotaspec" element={ <Toyotaspec /> } />
                <Route path="/ezdeal" element={ <EZDeal /> } />
                <Route path="/kinto" element={ <Kinto /> } />
            </Routes>
        </HashRouter> )
}
export default PageRouter;