import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Button, Offcanvas, NavDropdown, Form, Card, Row, Col, Carousel, Accordion } from 'react-bootstrap'
import './homePageStyle.css'
import spektra from '../../../assets/image/spektra.png'
import kinto from '../../../assets/image/kinto.png'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Mpv from "../../../component/car_tabs/MPV";
import Footer from "../../footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import HeaderTrans from "../../header";
import MobileTabs from "./MobileTabs";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { BASE_URL, Token } from "../../../Config";
import axios from "axios";
import { Link } from "react-router-dom";

export default function HomePage ()
{
    const [ key, setKey ] = useState( '1' );
    const [ show, setShow ] = React.useState( 1 );
    const [ productCate, setProductCate ] = useState( [] )
    const [ productList, setProductList ] = useState( [] )
    const data = [ 'fgccfg', 'ghvgvc', 'gvg', 'jjj', 'kkk', 'lll' ];
    const [ showButton, setShowButton ] = useState( false );

    useEffect( () =>
    {
        const handleScroll = () =>
        {
            setShowButton( window.scrollY > 300 );
        };
        window.addEventListener( 'scroll', handleScroll );
        return () =>
        {
            window.removeEventListener( 'scroll', handleScroll );
        };
    }, [] );

    const scrollToTop = () =>
    {
        window.scrollTo( { top: 0, behavior: 'smooth' } );
    };

    useEffect( () =>
    {
        handleProductCategory()
        handleFecthData()
    }, [ Token ] )


    const handleFecthData = async () =>
    {
        try
        {
            const response = await fetch( `${ BASE_URL }/api/frontend/products?category=1`, {
                headers: {
                    Authorization: `Bearer ${ Token }`,
                }
            } );
            const data = await response.json();
            console.log( "published-product", data );
            setProductList( data )
        } catch ( error )
        {
            console.error( 'Error fetching buyer data:', error );
        }
    }

    const handleProductCategory = async () =>
    {
        try
        {
            const response = await fetch( `${ BASE_URL }/api/frontend/product-categories`, {
                headers: {
                    Authorization: `Bearer ${ Token }`,
                }
            } );
            const data = await response.json();
            console.log( "published/profile", data );
            setProductCate( data )
        } catch ( error )
        {
            console.error( 'Error fetching buyer data:', error );
        }
    }

    const handleProductList = async ( k ) =>
    {
        setKey( k )
        try
        {
            const response = await fetch( `${ BASE_URL }/api/frontend/products?category=${ k }`, {
                headers: {
                    Authorization: `Bearer ${ Token }`,
                }
            } );
            const data = await response.json();
            console.log( "published-product", data );
            setProductList( data )
        } catch ( error )
        {
            console.error( 'Error fetching buyer data:', error );
        }
    }

    return (
        <>
            <HeaderTrans />
            <div className="web-display">
                <div className="mt-5 mb-5">
                    <h2 className="text-center temukam" style={ { fontWeight: '700', fontSize: '35px' } } >Temukan Mobil Toyota Impianmu </h2>
                    <div className="overflow-x-auto mt-5">
                        <Container>
                            { productCate.length > 0 ? (
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={ key }
                                    onSelect={ ( k ) => handleProductList( k ) }
                                    className="mb-3 tabb"
                                    style={ { width: '100%' } }
                                >
                                    { productCate?.map( ( item, index ) =>
                                    {
                                        console.log( "'dcdsc", item, index, key );

                                        return (
                                            <Tab key={ item.id } eventKey={ item.id.toString() } title={ item.name } className="tabs-lijds">
                                                <Mpv data={ productList } />
                                            </Tab>
                                        );
                                    } ) }
                                </Tabs>
                            ) : (
                                "No Data Found"
                            ) }
                        </Container>
                    </div>
                </div>
            </div>

            <div className="mobile-display">
                <h2 className="text-center mobile-text" >Temukan Mobil Toyota Impianmu </h2>
                <MobileTabs />
            </div>

            {/* spektra */ }
            <div className="box-3">
                <Container>
                    <div className="sub-box-3">
                        <Row>
                            <Col lg={ 6 } sm={ 12 } md={ 6 }>
                                <Card style={ { width: '100%', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                                    <Card.Img variant="top" src={ spektra } style={ { width: '100%', height: '100%', position: 'relative', top: '-26px' } } />
                                </Card>
                            </Col>
                            <Col lg={ 6 } sm={ 12 } md={ 6 }>
                                <div>
                                    <div className="Kinto-category-box">
                                        <h2 className="text-font">TOYOTA SPEKTAKULER</h2>
                                        <p className="fw-bold mt-3">Beli Avanza, Agya, Veloz, atau Rush dan dapatkan gratis paket suku cadang Rp 2.6 Juta, asuransi 2 tahun Rp 16 Juta, dan paket T-Care. Cek Promo Toyota Spektakuler sekarang!</p>

                                        <Link to="/toyotaspec" className="link-tyext">
                                            <button className="explore" style={ { cursor: 'pointer' } }>EXPLORE MORE</button>
                                        </Link>

                                        <Accordion defaultActiveKey={ null } className="mt-5">
                                            <Accordion.Item></Accordion.Item>

                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Apa itu Spektakuler?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text"> Spektakuler merupakan layanan Paket Kredit SPESIAL yang dikeluarkan oleh Toyota Astra Finance (TAF) & Astra Credit Company (ACC)</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Model apa saja yang mendapatkan paket Spektakuler?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text"> Saat ini, tersedia untuk Veloz, Avanza, Rush, Raize (selected area). untuk detail program yang berlaku silahkan kunjungi Dealer Resmi Toyota terdekat</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Apakah paket Spektakuler ini berlaku di seluruh Indonesia?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text"> Paket Spektakuler tersedia di seluruh Dealer Resmi Toyota di Indonesia</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Berapa DP minimum? Dapatkah disesuaikan dengan dana yang saya miliki?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text"> Paket Spektakuler menyediakan paket dengan DP mulai dari 20%</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Berapa tenor maksimal pada paket Spektakuler?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text"> Paket Spektakuler menyediakan paket dengan tenor hingga 5 tahun</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {/* kinto */ }
            <div className="box-2">
                <Container>
                    <div className="sub-box-3">
                        <Row>
                            <Col lg={ 6 } sm={ 12 } md={ 6 } className="order-lg-1 order-2">
                                <div>
                                    <div className="Kinto-category-box">
                                        <h2 className="text-font">KINTO</h2>
                                        <p className="fw-bold mt-3">Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya untukmu - no DP, bebas pajak progresif, biaya servis, parts & asuransi.</p>
                                        
                                        <Link to="/kinto" className="link-tyext">  <button className="explore" style={ { cursor: 'pointer' } }> EXPLORE MORE</button></Link>


                                        <Accordion defaultActiveKey={ null } className="mt-5">
                                        <Accordion.Item></Accordion.Item>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Apa itu KINTO?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text"> KINTO adalah layanan berlangganan mobil pertama di Indonesia dengan biaya Deposit sejumlah 1 bulan biaya berlangganan mobil.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Apa keuntungan memakai KINTO?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text">
                                                        <ul>
                                                            <li>Hassle Free Urusan perawatan mobil, asuransi, hingga perpanjang STNK akan di bantu oleh KINTO.</li>
                                                            <li>Peace of Mind Terhindar dari Pajak Progressive dan Depresiasi nilai mobil yang besar tiap tahunnya karena semua akan ditanggung oleh KINTO.</li>
                                                        </ul>
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Apa saja fasilitas yang didapatkan customer selama masa berlangganan?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text">
                                                        Customer akan mendapatkan layanan full service yang lengkap dan #BebasRibet seperti:
                                                        <ul>
                                                            <li>Servis Berkala & Penggantian Spare Part</li>
                                                            <li>Penggantian Aki & Ban </li>
                                                            <li>Perpanjangan STNK</li>
                                                            <li>Pajak & Perpanjangan STNK </li>
                                                            <li>Bantuan Darurat di Jalan </li>
                                                            <li>Fasilitas Mobil Pengganti (Opsional)</li>
                                                        </ul>
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Apakah layanan KINTO tersedia di seluruh Indonesia?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text"> Setiap Dealer Resmi Toyota siap melayani dan membantu kebutuhan KINTO. Untuk info lebih lanjut bisa menghubungi melalui whatsapp kami di 0896-3250-0550 / Call Center di 1500-550.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Apa saja dokumen yang dibutuhkan untuk pengajuan mobil kinto?</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text">
                                                        Dokumen yang diperlukan dalam pengajuan Mobil KINTO cukup sederhana yakni: <br></br> <br></br>
                                                        Customer Individu : <br></br>
                                                        <li>KTP Pemohon</li>
                                                        <li>KTP Pasangan (jika ada)</li>
                                                        <li>SIM A</li>
                                                        <li>Kartu Keluarga</li>
                                                        <li>NPWP</li>
                                                        <li>Rekening Koran 3 bulan terakhir.</li>
                                                        <br></br>
                                                        Perusahaan :
                                                        <li>Akta Pendirian</li>
                                                        <li>Akta Perubahan terbaru</li>
                                                        <li>Akta Penyesuaian 2008 jika perusahaan berdiri sebelum tahun 2008</li>
                                                        <li>NIB berbasis resiko</li>
                                                        <li>NPWP</li>
                                                        <li>KTP Direksi yang menandatangani kontrak</li>
                                                        <li>Rekening Perusahaan 3 bulan terakhir.</li>
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>Apakah mobil KINTO dapat digunakan untuk kepentingan komersial ? (misal: taksi online)</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={ { textAlign: 'left', color: '#6D6D6D', fontSize: '16px', fontWeight: 400 } } className="accordian-text"> Selama masa berlangganan, Layanan KINTO hanya dapat digunakan untuk kepentingan pribadi / personal dan operasional Perusahaan. Hubungi pihak sales KINTO kami untuk informasi lebih lengkap melalui whatsapp di 0896-3250-0550 / Call Center di 1500-550</p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={ 6 } sm={ 12 } md={ 6 } className="order-lg-1 order-1">
                                <Card style={ { width: '100%', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                                    <Card.Img variant="top" src={ kinto } style={ { width: '100%', height: '100%' } } />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <div className="bottom-button">
                <hr className="hhhrrr" />
                <button onClick={ scrollToTop }>Back to top​ <FontAwesomeIcon icon={ faAngleUp } /> </button>
            </div>
            <hr />
            <Footer />
        </>
    )
}