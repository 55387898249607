import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import car from '../../../assets/image/car.png'
import './MPVStyle.css'
import { BASE_URL, Token } from '../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

export default function Mpv ( { data } )
{
    return (
        <>
            { data && data?.length > 0 ? (
                <Row className='text-center mx-auto mpv-dynamic-margin '>
                    { data?.map( ( item, index ) =>
                    {
                        let formattedValue = ( item.price );
                        console.log( formattedValue );
                        return (
                            <>
                                <Col lg={ 3 } md={ 6 } sm={ 12 } className='' style={ { display: 'flex', justifyContent: 'center' } } >
                                    <Card style={ { width: '18rem', border: 'none' } }>
                                        {/* <Card.Img variant="top" src={item.image}  /> */ }
                                        <div style={ { overflow: 'hidden', position: 'relative', paddingTop: '5%', paddingBottom: '2%' } }>
                                            <Card.Img
                                                variant="top"
                                                src={ item.image }
                                                style={ {
                                                    transition: 'transform 0.3s',

                                                    height: 'auto',
                                                } }
                                                onMouseOver={ ( e ) =>
                                                {
                                                    e.currentTarget.style.transform = 'scale(1.2)';
                                                } }
                                                onMouseOut={ ( e ) =>
                                                {
                                                    e.currentTarget.style.transform = 'scale(1)';
                                                } }
                                                className='image-dynanmic-size'
                                            />
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={ { textAlign: 'left', fontWeight: '900', fontSize: '15px' } }>{ item.name }</Card.Title>
                                            <Card.Text style={ { textAlign: 'left', fontWeight: '500', fontSize: '13px' } }>
                                                Starting from <span className='span-text'>Rp { formattedValue }</span>
                                            </Card.Text>
                                            <Card.Text className='card-bottom'>
                                                Get Your Offer <FontAwesomeIcon icon={ faAngleRight } />
                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                        )
                    } ) }
                </Row>
            ) : (
                "no data is found"
            )
            }
        </>
    )
}
