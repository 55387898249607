import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import './footerStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import whatsapp from '../../assets/image/whatsapp.png'
import facebook from '../../assets/image/Facebook.png'
import instagram from '../../assets/image/Instagram.png'
import twitter from '../../assets/image/Twitter.png'
import youtube from '../../assets/image/YouTube.png'
import tiktok from '../../assets/image/Tiktok.png'
import cust from '../../assets/image/cust.png'

export default function Footer() {
    return (
        <>
            <div>
                <Container>
                    <Row className='mt-5'>
                        <Col lg={2} md={4} sm={6}>
                            <div className='footer-item-list-box'>

                                <ul>
                                    <li className='title-footer-list'>Beyond Solution</li>
                                    <li className="list-footer-com">Toyota Spektakuler</li>
                                    <li className="list-footer-com">Toyota EZ Deal</li>
                                    <li className="list-footer-com">Kinto</li>
                                    <li className="list-footer-com">Toyota Financial Service</li>
                                    <li className="list-footer-com">Get Your Offer</li>
                                    <li className="list-footer-com">Toyota Trade In</li>
                                    <li className="list-footer-com">Form Inquiry</li>
                                    <li className="list-footer-com">Pricelist</li>
                                </ul>

                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={6}>
                            <div className='footer-item-list-box'>

                                <ul>
                                    <li className='title-footer-list'>Beyond Experience</li>
                                    <li className="list-footer-com">E-brochure</li>
                                    <li className="list-footer-com">Toyota Live Showroom</li>
                                    <li className="list-footer-com">Toyota Interactive Virtual Assistant</li>
                                    <li className="list-footer-com">Toyota Official Store Solution</li>
                                    <li className="list-footer-com">Find Dealer</li>
                                    <li className="list-footer-com">Booking Service</li>
                                    <li className="list-footer-com">Aftersales</li>
                                    <li className="list-footer-com">Toyota Mobile Service</li>
                                    <li className="list-footer-com">T-Care</li>
                                    <li className="list-footer-com">mToyota</li>
                                    <li className="list-footer-com">Toyota Genuine Accessories</li>
                                    <li className="list-footer-com">Special Service Campaign</li>
                                </ul>

                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={6}>
                            <div className='footer-item-list-box'>

                                <ul>
                                    <li className='title-footer-list'>Beyond Technology</li>
                                    <li className="list-footer-com">T-intouch</li>
                                    <li className="list-footer-com">Toyota Safety Sense</li>
                                    <li className="list-footer-com">TNGA</li>
                                    <li className="list-footer-com">Engine</li>
                                    <li className="list-footer-com">Android Auto</li>

                                </ul>

                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={6}>
                            <div className='footer-item-list-box'>

                                <ul>
                                    <li className='title-footer-list'>Beyond Sustainability</li>
                                    <li className="list-footer-com">ITFE</li>
                                    <li className="list-footer-com">Toyota Berbagi</li>

                                </ul>

                            </div>

                        </Col>
                        <Col lg={1} md={4} sm={6}>
                            <div className='footer-item-list-box'>

                                <ul>
                                    <li className='title-footer-list'>More</li>
                                    <li className="list-footer-com">Pressroom</li>
                                    <li className="list-footer-com">Owners Manual</li>
                                    <li className="list-footer-com">News & Information</li>

                                </ul>

                            </div>
                        </Col>
                        <Col lg={3} md={4} sm={6}>
                            <div className='footer-item-list-box'>
                                <ul>
                                    <li className='title-footer-list'>Join us on</li>
                                </ul>
                                <div>
                                    <ul style={{ display: 'flex', position: 'relative', top: '-12px' }}>
                                        <li className="list-footer-img"><img src={whatsapp} /></li>
                                        <li className="list-footer-img" style={{ marginLeft: '10px' }}><img src={instagram} /></li>
                                        <li className="list-footer-img" style={{ marginLeft: '10px' }}><img src={facebook} /></li>
                                        <li className="list-footer-img" style={{ marginLeft: '10px' }}><img src={twitter} /></li>
                                        <li className="list-footer-img" style={{ marginLeft: '10px' }}><img src={youtube} /></li>
                                        <li className="list-footer-img" style={{ marginLeft: '10px' }}><img src={tiktok} /></li>
                                    </ul>
                                </div>
                                <div className='image-cust'>
                                    <img src={cust} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
                <hr />
                <div className='footer-below'>
                    <p>©2023 Toyota Astra Motor</p>
                    <p>All information applies to Indonesia vehicles only</p>
                </div>
                <div className='footer-below'>
                    <p> <span> Privacy Policy &nbsp; | &nbsp;</span> <span>&nbsp; Legal Cookie &nbsp;|&nbsp;</span> <span>&nbsp; Contact Us</span></p>
                </div>
            </div>
        </>
    )
}